function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function storePushSubscription(pushSubscription, uniqueId) {
    //const token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    //pushSubscription.uniqueId = uniqueId;

    //console.log('pushSubscription', pushSubscription);
    const body = JSON.stringify(pushSubscription);

    console.log('body', body);

    const apiUrl = process.env.VUE_APP_API_URL || 'https://tapcrawl.com/';
    
    fetch(apiUrl + 'api/push/' + uniqueId, {
        method: 'POST',
        body: body,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'X-CSRF-Token': token
        }
    })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        });
}

function subscribeUser(uniqueId) {
    navigator.serviceWorker.ready
        .then((registration) => {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    'BCQgto1w2zOe1MqpaLa06__UIYzF_f4DOUEAAtDaYuzej5y_mEl1gcQwwzRgWiBFhNF1uRDorX341tTLRO9UgMk'
                )
            };

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then((pushSubscription) => {
            console.log('Received PushSubscription: ', JSON.stringify(pushSubscription), uniqueId);
            storePushSubscription(pushSubscription, uniqueId);
        });
}

function initPush(uniqueId) {
    if (!navigator.serviceWorker.ready) {
        return;
    }

    new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    })
        .then((permissionResult) => {
            if (permissionResult !== 'granted') {
                throw new Error('We weren\'t granted permission.');
            }
            subscribeUser(uniqueId);
        });
}

export function initSW(uniqueId) {
    if (!("serviceWorker" in navigator)) {
        //service worker isn't supported
        return;
    }

    //don't use it here if you use service worker
    //for other stuff.
    if (!("PushManager" in window)) {
        //push isn't supported
        return;
    }

    //register the service worker
    navigator.serviceWorker.register('../sw.js')
        .then(() => {
            console.log('serviceWorker installed for' + uniqueId + '!');
            initPush(uniqueId);
        })
        .catch((err) => {
            console.log(err)
        });
}