import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { initSW } from './app/enable-push';

import { IonicVue } from '@ionic/vue';

import { Plugins, AppState } from '@capacitor/core';
const { App: CapApp } = Plugins;

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'ios'
  })
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});

CapApp.addListener('appUrlOpen', function (data: any) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = data.url.split(".com").pop();
  console.log('slug is: ' + slug);

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug
    });
  }
});