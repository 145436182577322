<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <!--ion-buttons slot="end">
          <ion-button @click="() => router.push('/home')">
            Home-->
            <!--ion-icon slot="end" :icon="chevronForward"></ion-icon-->
          <!--/ion-button>
        </ion-buttons-->
        <ion-title>New Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>You're all set!</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Share code or scan:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid style="display: flex; flex-direction: row; justify-content: space-around">
        <ion-row>
          <ion-col>
            <ion-button expand="block"  size="large" color="dark" strong>{{ null == crawl.code ? '' : crawl.code[0]  }}</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block"  size="large" color="dark" strong>{{ null == crawl.code ? '' : crawl.code[1]  }}</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" color="dark" strong>{{ null == crawl.code ? '' : crawl.code[2]  }}</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" color="dark" strong>{{ null == crawl.code ? '' : crawl.code[3]  }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <ion-img :src="apiUrl + 'api/games/qr/' + crawl.code + '.svg'"
                style="max-width: 75vw; width: 100%; margin: 0 auto; max-height: 30vh; height: 100%">
                </ion-img>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Everybody in?</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong color="success" @click="startCrawl">Start the crawl</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="subscribeToWebPush">Allow Notifications</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  //IonBackButton,
  //IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { warning, chevronForward } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'
import { initSW } from '../app/enable-push';
import { onMounted, getCurrentInstance } from 'vue'

export default defineComponent({
  name: 'NewCrawlNumStops',
  components: {
    //IonBackButton,
    //IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage } = Plugins;
    return {  warning,
              chevronForward,
              console,
              router: useRouter(),
              Storage}
  },
  data() {
      return {
          foo: 'bar',
          'crawl': {},
          apiUrl: ""
      }
  },
  created: async function() {
    console.log('created');
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.apiUrl = apiMethods.apiUrl;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
  },
  methods: {
    startCrawl: async function() {
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      const response = await fetch(apiMethods.apiUrl + 'api/games/start/' + this.crawl.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "unique_id": uniqueId,
            }),
        });
      const clueData = await response.json();
      this.router.push('/crawl-clue');
    },
    subscribeToWebPush: async function() {
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      initSW(uniqueId);
    }
  }
});
</script>

<style scoped>

</style>