<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>Join Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Join in!</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Share code:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              :value="code"
              placeholder="Enter code"
              type="false ? 'tel' : 'text'"
              maxlength="4"
              @ionInput="code = $event.target.value;"
              style="text-align: center;"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Your name:</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              :value="name"
              placeholder="Enter your name"
              type="text"
              @ionInput="name = $event.target.value;"
              style="text-align: center;"></ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
       <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="joinCrawl">Join</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button expand="block" size="large" fill="solid" strong @click="subscribeToWebPush">Allow Notifications</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  //IonCard,
  //IonCardHeader,
  //IonCardContent,
  //IonCardSubtitle,
  //IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonInput,
  alertController,
} from '@ionic/vue';
import { warning } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js';
import { onMounted, getCurrentInstance } from 'vue';
import { WonderPush } from '@ionic-native/wonderpush/ngx';
import { initSW } from '../app/enable-push';

export default defineComponent({
  name: 'JoinCrawl',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    //IonCard,
    //IonCardHeader,
    //IonCardContent,
    //IonCardSubtitle,
    //IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonInput,
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device;
    apiMethods.crawlApi.WonderPush = WonderPush;
    return {  warning,
              console,
              apiMethods,
              router: useRouter(),
              Storage,
              Device }
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
    this.code = this.router.currentRoute.value.params.code;
  },
  data: function() {
    return {
      code: "",
      name: "",
      uniqueId: null
    }
  },
  methods: {
    joinCrawl: async function(duration) { // joining crawl
      const self = this;
      console.log('Joining crawl with code: ' + this.code);
      const device = await self.Device.getInfo();
      const uniqueId = await apiMethods.crawlApi.getUniqueId();

      const wonderPush = new WonderPush;
      let pushToken = null;
      try {
        pushToken = await wonderPush.getPushToken();
      } catch (e) {
        console.log("no push token found", e);
      }

      await fetch( apiMethods.apiUrl + 'api/games/join/' + this.code, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "code": this.code,
          "name": this.name,
          "device_id": device.uuid,
          "device_platform": device.platform,
          "unique_id": uniqueId,
          "push": pushToken,
        }),
      })
      .then(res => res.json())
      .then(function(data){
        console.log(data);
        self.setCrawl(data);
        self.setName(self.name);
        apiMethods.crawlApi.setCode(data.code);
        self.setUniqueId(data.player_unique_id);
        apiMethods.crawlApi.setPlayer({
            "unique_id": data.player_unique_id,
            "name": data.player_name,
            "contact_value": data.player_contact_value,
            "contact_method": data.player_contact_method
        });
        self.router.push('/join-success');
      }).catch(function(e) {
        console.log(e);
        self.presentAlert();
      });
    },
    setCrawl: async function(crawl) {
      await this.Storage.set({
        key: 'crawl',
        value: JSON.stringify(crawl)
      });
      this.crawl = crawl;
    },
    setName: async function(name) {
      await this.Storage.set({
        key: 'name',
        value: name,
      });
      this.name = name;
    },
    setUniqueId: async function(uniqueId) {
      await this.Storage.set({
        key: 'unique_id',
        value: uniqueId,
      });
      this.uniqueId = uniqueId;
    },
    subscribeToWebPush: async function() {
      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      initSW(uniqueId);
    },
    presentAlert: async function() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Shit.',
          subHeader: '😕',
          message: 'We couldn\'t add you to this crawl. Make sure you\'ve got a good connection and try again.',
          buttons: ['OK'],
        });
      return alert.present();
    },
  }
});
</script>

<style scoped>

</style>